<template>    
  <v-dialog max-width="500px" v-model="dialog">
    <v-card >              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon>mdi-cloud-upload-outline</v-icon>
          엑셀 업로드
        </v-toolbar-title>          
        <v-divider class="mx-4" inset vertical></v-divider>                    
      </v-toolbar>
      <v-divider></v-divider>              
      <v-card-text>
        <v-file-input
          class="mb-n7"      
          show-size      
          accept=".xlsx"
          v-model="NowSelFile"   
          v-on:change="readFile(NowSelFile)"      
          prepend-icon="mdi-microsoft-excel"                           
          label="파일을 선택하세요."></v-file-input>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="black darken-1" text @click="closePage()"><v-icon class="mr-2">mdi-close</v-icon>취소</v-btn>        
      </v-card-actions>
    </v-card>    
  </v-dialog>  
</template>

<script>
// import axios from "axios";
// import BasicInfo from "@/BasicInfo.js";
// import EventBus from '@/eventBus.js';
// import { mapState } from "vuex";
import XLSX from 'xlsx';

export default {
  components: {    
  },
  data() {
    return {
      dialog: false,   
      NowSelFile : null,          
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },

  computed: {
  },

  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage(){                   
      this.dialog = false;
    },
    readFile(file) {      
      console.log(file);  
      let reader = new FileReader();
      let tmpResult = {};
      reader.onload = (e) => {
        console.log(e);
        let data = reader.result;
        let workbook = XLSX.read(data, {type: 'binary'});
        workbook.SheetNames.forEach(sheetName => {                
            console.log(workbook.Sheets[sheetName].A1);
            const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            tmpResult = roa;
        });
        console.log(tmpResult);

        this.$emit("onReadFile", tmpResult);        

        this.dialog = false;

        tmpResult;
      };        
      reader.readAsBinaryString(file);
    },


    Show: function() {
      this.NowSelFile = null;
      this.dialog = true;      
    },
    
  }
};
</script>
