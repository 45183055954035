<template>    
  <v-dialog
    ref="dialog"
    v-model="modal"    
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="sCalDate"
        :label="Caption"                
        readonly
        class="mt-n4 mb-n4"     
        v-bind="attrs"
        v-on="on"        
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="sCalDate"
      :title-date-format ="getDate"      
      :header-date-format="getHeader"
      :month-format="getMonth"
      :weekday-format="getDay"  
      scrollable>      
      <v-spacer></v-spacer>        
        <v-btn text color="primary" @click="onUpdateDate" >
          <v-icon big class="mr-2">check</v-icon>선택
        </v-btn>
        <v-btn text color="black" @click="modal = false">
          <v-icon big class="mr-2">cancel</v-icon>취소
        </v-btn>      
    </v-date-picker> 
  </v-dialog>
</template>

<script>

const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토', ];
const monthOfYear = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export default {
  data: () => ({    
    modal : false,    
    sCalDate : "",       
  }),
  props: ['value', 'Caption', 'sNowSelectDate'],  
  mounted() {        
    var s = this.sNowSelectDate;
    if (s == "") {
      s = new Date().toISOString().substr(0, 10);
    }
    
    this.sCalDate = s;
  },
  methods: {
    onChange(data){      
      var s = data;      
      if (s == "") {
        s = new Date().toISOString().substr(0, 10);
        this.sCalDate = s;        
      }
    },
    getDay(date){
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },

    getMonth(date){
      let i = new Date(date).getMonth(date);      
      return  monthOfYear[i] + "월"
    },

    getHeader(date){
      let i = new Date(date).getMonth(date);
      let sYear = new Date(date).getFullYear(date) ;
      return  sYear + "년 " + monthOfYear[i] + "월"
    },

    getDate(date){
      let i = new Date(date).getMonth(date);      
      let sDay =  new Date(date).getDate(date);
      let iWeek = new Date(date).getDay(date);      

      return monthOfYear[i] + "/" + sDay + " " + daysOfWeek[iWeek] + "요일";
    },    
    
    onUpdateDate() {     
      this.modal = false;        
      this.$emit("onChaange", this.sCalDate);
    },

  }
};
</script>
