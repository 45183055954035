<template>  
  <div>
    <div class="background_basic">      
      <div style="height:1px"></div>
      <v-card class="ma-4">
        <v-toolbar flat dense>
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-text-box-plus</v-icon>구성원관리
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>
          <v-btn color="blue" text @click="ExcelExpert('구성원관리')" class = "ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>        

          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="SelectUserAll()" class = "ml-2 font-weight-bold text-subtitle-1" :disabled="selected.length == 0">
            <v-icon big class="mr-2">mdi-delete</v-icon>일괄맵선택
          </v-btn>

          <v-btn color="red" text @click="DeleteAll()" class = "ml-2 font-weight-bold text-subtitle-1" :disabled="selected.length == 0">
            <v-icon big class="mr-2">mdi-delete</v-icon>일괄삭제
          </v-btn>
          <v-btn color="blue" text @click="uploadExcel()" class = "ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-cloud-upload-outline</v-icon>엑셀업로드
          </v-btn>
          <v-btn color="blue" text @click="GetSKMRMember()" class="font-weight-bold text-subtitle-1" :disabled="loading3">
            <v-icon big class="mr-2">mdi-cloud-download</v-icon>데이터불러오기
          </v-btn>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn color="blue" text v-on="on" :disabled="!ProgramPermissionInfo.okC" @click="ClearDialog()" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">post_add</v-icon>신규등록
              </v-btn>
            </template>
            <v-card ref="form">
              <v-card-title >
                <span class="font-weight-bold">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="ma-0">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cUserId" label="사용자ID" :disabled="true"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cUserNm" label="이름"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cEmail" label="E-MAIL"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cTel" label="연락처"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cPartNm" label="부서"></v-text-field>                    
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field dense v-model="editedItem.cPartLev" label="직책"></v-text-field>                    
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="isEditMode">
                      <v-btn class="mt-n2" color="blue" dark @click="ResetPassword()"><v-icon class="mr-2">mdi-form-textbox-password</v-icon> 비밀번호 초기화</v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select dense v-model="editedItem.cUserRoleId" label="권한명" :items="UserRoleInfo" item-text="sNm" item-value="sId"></v-select> 
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select dense label="구분" :items="zUserRoleType" v-model="editedItem.cLev" item-value="sId" item-text="sNm"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select dense v-model="editedItem.cIsEmployed" label="재직여부" :items="zIsEmployed" item-text="sNm" item-value="sId"></v-select>                    
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <eCalendar v-if="editedItem.cIsEmployed == 'N'" :sNowSelectDate="editedItem.cUnregDate" Caption="퇴사일자"  @onChaange="onCalChange"></eCalendar>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>                
                <v-spacer></v-spacer>              
                <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="font-weight-bold text-subtitle-1">
                  <v-icon big class="mr-2">save</v-icon>저장
                </v-btn>
                <v-btn color="grey darken-2" text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
                  <v-icon big class="mr-2">cancel</v-icon>취소
                </v-btn>              
              </v-card-actions>              
              <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>
            </v-card>
          </v-dialog>        
          <v-progress-linear :active="loading3" :indeterminate="loading3" absolute bottom color="blue accent-4"></v-progress-linear>    
        </v-toolbar>              
        <v-divider></v-divider>            
        <v-card flat class="d-flex pt-2 pl-4">                                                     
          <v-text-field          
            v-model="cNowSearchKeyWord"
            class="ml-0 mb-n4"
            label="검색어"
            placeholder="이름 or 사용자Id or 부서명 or 직책 or 연락처 입력"
            persistent-placeholder
            style="max-width:400px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>
          
          <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>
          <div class="d-flex ml-5 mt-5">
            <v-switch color="grey darken-3" v-model="bUseOnly" class="mt-n2 mb-n7" @change="Search()"></v-switch>
            
            <div class="mt-n1">사용중만 검색</div>
          </div>
        </v-card>
      </v-card>      
      <v-data-table           
        class="ma-4 elevation-3"
        v-model="selected"
        show-select
        item-key="cUserId"
        :items-per-page="15" 
        fixed-header 
        height="calc(100vh - 235px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick">
        <template v-slot:item.action="{ item }">
          <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">edit</v-icon>
          <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD">delete</v-icon>
        </template>
        
        <template v-slot:item.cIsEmployed="{ item }">
          <v-chip v-if="item.cIsEmployed == 'Y' " color="grey lighten-3" small label>재직</v-chip>
          <v-chip v-else small label color="red lighten-2" dark>퇴사</v-chip>
        </template> 
        <template v-slot:item.cUserRoleNm="{ item }">
          <div v-if="item.cUserRoleNm == 'NONE' " color="grey lighten-3" small label>없음</div>
          <div v-else>{{item.cUserRoleNm}}</div>
        </template> 

        <template v-slot:item.bUse="{ item }">
          <v-switch color="grey darken-3" v-model="item.bUse" class="mt-n2 mb-n7" @change="changeUse(item)"></v-switch>           
        </template>
        <template v-slot:item.bLocked="{ item }">
          <v-switch color="grey darken-3" v-model="item.bLocked" class="mt-n2 mb-n7" @change="changeLocked(item)"></v-switch>           
        </template>
        <template v-slot:item.cLev="{ item }">
          <v-chip v-if="item.cLev == 'N'" label small color="grey lighten-3" >일반</v-chip>                     
          <v-chip v-if="item.cLev == 'M'" label small color="blue darken-1" dark>관리자</v-chip>           
        </template>
        <template v-slot:item.cPWCntInitial="{ item }">
          <div v-if="item.cPwdFailCnt == '0'">
            {{item.cPwdFailCnt}}
          </div>
          <div v-else>
            <v-btn small color="grey darken-3" dark @click="PWCntInitial(item)" dense> <v-icon small class="mr-1">refresh</v-icon>초기화 ({{item.cPwdFailCnt}})</v-btn>
          </div>
        </template>

        <template v-slot:item.cMapCds="{ item }">
          <v-btn small @click="GetMapItem(item)" :disabled="!ProgramPermissionInfo.okU" color="grey darken-2" dark><v-icon class="mr-1">map</v-icon>맵
          </v-btn>          
        </template>
        
        <template v-slot:item.cMapNms="{ item }">
          <v-chip label small v-if ="item.cMapNms != ''" color="grey lighten-3">{{item.cMapNms}}</v-chip>
          <v-chip label small v-else color="red lighten-2" dark >선택안됨</v-chip>
        </template>

        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
      
      <div style="height:1px"></div>
    </div>
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <MsgBox ref="MsgBoxRef2" @onMsgBox="onMsgBoxFuncAll" />    
    <GetSelMap ref="GetSelMapRef" @onGetMap="onGetMap" />     
    <GetSelMap ref="GetSelMapRef2" @onGetMap="onGetMap2" />     
    <UploadExcel ref="UploadExcelRef" @onReadFile="onReadFile"/> 
    <v-dialog max-width="2048px" v-model="bReadFile">
      <v-card >              
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon>mdi-cloud-upload-outline</v-icon>
            엑셀 업로드
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>                    
        </v-toolbar>
        <v-data-table 
          :items-per-page="-1" 
          fixed-header 
          height="calc(100vh - 300px)" 
          :headers="Fileheaders" 
          :items="FileRecords"          
          >                  
          <template v-slot:item.cUse="{ item }">
            <v-chip v-if="item.cUse == 'Y'" color="grey lighten-3" label small>사용</v-chip>
            <v-chip v-if="item.cUse == 'N'" color="red darken-4" label small dark>중지</v-chip>
          </template>
          <template v-slot:item.cLocked="{ item }">
            <v-chip v-if="item.cLocked == 'Y'" color="red darken-3" label small dark>잠금</v-chip>
            <v-chip v-if="item.cLocked == 'N'" color="grey lighten-4" label small>정상</v-chip>
          </template>

          <template v-slot:no-data>
            <p>No data available.</p>
          </template>
   
        </v-data-table>

        <v-divider></v-divider>
        <v-progress-linear :active="loading2" :indeterminate="loading2" absolute bottom color="blue accent-4"></v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="black darken-1" text @click="uploadExcelFile()" :disabled="FileRecords.length == 0"><v-icon class="mr-2">mdi-upload</v-icon>확인</v-btn>        
          <v-btn color="black darken-1" text @click="bReadFile=false"><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>        
        </v-card-actions>
      </v-card>    
    </v-dialog>  
  </div>
</template>

<script>
import MsgBox from "../components/MsgBox.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import SHA256 from "sha256"; 
import eCalendar from "@/components/eCalendar.vue";
import UploadExcel from "@/components/UploadExcel.vue";  
import GetSelMap from "@/components/GetSelMap.vue";


export default {
  components: {
    MsgBox, 
    eCalendar, 
    UploadExcel,
    GetSelMap,
  },
  data: () => ({
    cGroup : "ALL",
    isGroupALL : false,        
    selected: [],
    bUseOnly : true,
    show1: false,    
    cNowSearchKeyWord : "",
    loading : false,
    loading2 : false,
    loading3 : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,
    model : 0,    
    bReadFile : false,
    FileRecords : [],
    Fileheaders: [      
      { class: "font-weight-bold subtitle-2", text: "사용자ID", value: "cUserId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "회사코드", value: "cCorpId", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "회사명", value: "cCorpNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "부서", value: "cPartNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "직책", value: "cPartLev", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "재직여부", value: "cIsEmployed", align: "center",  sortable: false},
      { class: "font-weight-bold subtitle-2", text: "이메일", value: "cEmail", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "연락처", value: "cTel", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "생년월일", value: "cBirth", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "맵코드", value: "cMapCds", align: "center" },    
      { class: "font-weight-bold subtitle-2", text: "맵", value: "cMapNms", align: "left" },          
      { class: "font-weight-bold subtitle-2", text: "구분", value: "cLev", align: "center" },    
      { class: "font-weight-bold subtitle-2", text: "잠금", value: "cLocked", align: "left",  sortable: false},
      { class: "font-weight-bold subtitle-2", text: "사용", value: "cUse", align: "left",  sortable: false},      
    ],

    headers: [      
      { class: "font-weight-bold subtitle-2", text: "사용자ID", value: "cUserId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center", width:100},
      { class: "font-weight-bold subtitle-2", text: "이메일", value: "cEmail", align: "center", width:100},
      { class: "font-weight-bold subtitle-2", text: "연락처", value: "cTel", align: "center", width:150},
      { class: "font-weight-bold subtitle-2", text: "부서", value: "cPartNm", align: "center", width:150},
      { class: "font-weight-bold subtitle-2", text: "직책", value: "cPartLev", align: "center", width:80},
      { class: "font-weight-bold subtitle-2", text: "재직여부", value: "cIsEmployed", align: "center",  sortable: false},
      { class: "font-weight-bold subtitle-2", text: "맵선택", value: "cMapCds", align: "center" },    
      { class: "font-weight-bold subtitle-2", text: "맵", value: "cMapNms", align: "left" },            
      { class: "font-weight-bold subtitle-2", text: "구분", value: "cLev", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "권한", value: "cUserRoleNm", align: "center", width:100,  sortable: true},
      // { class: "font-weight-bold subtitle-2", text: "로그인실패&nbsp횟수초기화", value: "cPWCntInitial", align: "center",  sortable: false},
      { class: "font-weight-bold subtitle-2", text: "로그인실패 횟수초기화", value: "cPWCntInitial", align: "center", width:30, sortable: false},
      { class: "font-weight-bold subtitle-2", text: "잠금", value: "bLocked", align: "left",  sortable: false},
      { class: "font-weight-bold subtitle-2", text: "사용", value: "bUse", align: "left",  sortable: false},      
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false, width:80,},
      
      
    ],
    Records: [],
    editedIndex: -1,
    // userId(스토어), cDel(golang), cEtc(golang)만 제외하고 테이블의 항목을 설정함 
    editedItem: { 
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "N",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPartNm: "",
      cPartLev: "",
      cMapCds: "",
      cMapNms: "",
      cIsEmployed: "Y",
      cUnregDate: "",
      cUserRoleId: "NONE",
      cMapTreeIdx: "NONE",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "Y",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    deletedItem: {
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "N",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPartNm: "",
      cPartLev: "",
      cIsEmployed: "Y",
      cUnregDate: "",
      cUserRoleId: "NONE",
      cMapTreeIdx: "NONE",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "Y",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    defaultItem: {
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "N",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPartNm: "",
      cPartLev: "",
      cMapCds: "",
      cMapNms: "",
      cIsEmployed: "Y",
      cUnregDate: "",
      cUserRoleId: "NONE",
      cMapTreeIdx: "NONE",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "Y",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zIsEmployed", "UserRoleInfo", "zUserRoleType", "zGroupAll", "zGroup", ]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정";
    },
  },

  created() {
    this.$store.dispatch("GetUserRoleInfo"); 

    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }


    
    this.initialize();
  },

  methods: {    


    onCalChange(s){      
      this.editedItem.cUnregDate = s;      
    },
    
    initialize() {
      this.cNowSearchKeyWord = "";
      this.bUseOnly = true;
      this.Search();
      this.model = 0;
    },

   
    ClearDialog() {      
      this.isEditMode = false;
      this.editedItem = Object.assign({}, this.defaultItem);      
      this.editedItem.cGroupId = this.$store.state.UserInfo.GroupId;
      this.editedItem.cUserId = "";
      this.editedIndex = -1;                      
    },

    
    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    
    Search() {
      this.Records = [];
      var posData = {
        // cStartDatetime: this.sStartDate + " 00:00:00",  
        // cEndDatetime  : this.sEndDate + " 23:59:59",
        cGroupId: this.$store.state.UserInfo.GroupId,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cUseOnly : "Y",
      };

      if (this.isGroupALL) {
        if (this.cGroup != "ALL") {
          posData.cGroupId = this.cGroup;
        }
      }

      if (!this.bUseOnly) {
        posData.cUseOnly = "N";
      }

      axios
        .post(BasicInfo.UIL_API + "GetUser", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          // console.log(res.data.Result);

          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;            
            for (var i = 0; i < this.Records.length; i++) {
              this.Records[i].bLocked = this.Records[i].cLocked == 'Y';            
              this.Records[i].bUse = this.Records[i].cUse == 'Y';         
              this.Records[i].zMap = res.data.Result[i].cMapCds.split(",");   
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    // 비밀번호실패 횟수초기화
    PWCntInitial(item) {
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);   
      var posData = {
        cEditUserId: this.$store.state.UserInfo.UserId,
        cUserId : this.editedItem.cUserId, 
        cPwdFailCnt : 0,
      };
      
      axios
        .post(BasicInfo.UIL_API + "PWCntInitial", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd === "00") {
            this.dialog = false;
            this.Records[this.editedIndex].cPwdFailCnt = "0";
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },


    save() {
      // if (this.editedItem.cUserId == "" ) {
      //   EventBus.$emit("onShowMsg",true, "사용자ID는 필수입력 항목입니다.");
      //   return;
      // }
      if (this.editedItem.cUserNm == "" ) {
        EventBus.$emit("onShowMsg",true, "이름은 필수입력 항목입니다.");
        return;
      }
   
      var posData = {
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        cUserId: this.editedItem.cUserId,
        cNewUserId: "",
        cEditUserId: "",
        cUserNm: this.editedItem.cUserNm,
        cPwd : "",
        cInfo: this.editedItem.cInfo,
        cLev: this.editedItem.cLev,
        cGroupId: this.editedItem.cGroupId,        
        cCorpId: this.editedItem.cGroupId,
        cCorpNm: this.editedItem.cCorpNm,        
        cPartNm: this.editedItem.cPartNm,
        cPartLev: this.editedItem.cPartLev,
        cIsEmployed: this.editedItem.cIsEmployed,
        cUnregDate: this.editedItem.cUnregDate,
        cUserRoleId: this.editedItem.cUserRoleId,
        cMapTreeIdx: this.editedItem.cMapTreeIdx,
        cEmail: this.editedItem.cEmail,
        cBirth: this.editedItem.cBirth,
        cPwdFailCnt: this.editedItem.cPwdFailCnt,
        cLocked: this.editedItem.cLocked,
        cTel: this.editedItem.cTel,
        cUse: this.editedItem.cUse,
      }

      if (this.isEditMode) {
        posData.cEditUserId = this.editedItem.cUserId;
      } else {        
        posData.cNewUserId = this.editedItem.cUserId;        
      }

      if (this.editedItem.cIsEmployed == "Y") {
        this.editedItem.cUnregDate = "";
      }      

      this.loading = true;     

      axios
        .post(BasicInfo.UIL_API + "SetUser", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {
            if (this.isEditMode) {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUserId = res.data.Result[0].cUserId;
              this.editedItem.id = this.Records.length +1;     // 자동카운트시
              this.Records.push(this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },


    ResetPassword() {
      var posData = {
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        cUserId: this.editedItem.cUserId,        
        cPwd : SHA256(this.editedItem.cUserId + "1Qwer1234!" + "@" + this.editedItem.cUserId),        
      }

      
      this.loading = true;

      axios
        .post(BasicInfo.UIL_API + "ResetPassword", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {                    
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       
      this.dialog = true;
    },

    
    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cUserId + "/" + this.deletedItem.cUserNm + 
          "]"
      );
    },

    
    onMsgBoxFunc(result) {
      if (result === true) {        
        var posData = {
          cUpdateUserId: this.$store.state.UserInfo.UserId,
          UserId: this.deletedItem.UserId,
        };
        axios
          .post(BasicInfo.UIL_API + "DelUser", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1); 
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },
    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.Fileheaders);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

    changeUse(item){            
      item.cUse = "N"
      if (item.bUse){ item.cUse = "Y" }
      
      var posData = {
        cUserId : this.$store.state.UserInfo.UserId,
        cEditUserId : item.cUserId,
        cUse : item.cUse,
      }
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetUserInfoUse", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

    changeLocked(item){            
      item.cLocked = "N"
      if (item.bLocked){ item.cLocked = "Y" }
      
      var posData = {
        cUserId : this.$store.state.UserInfo.UserId,
        cEditUserId : item.cUserId,
        cLocked : item.cLocked,
      }
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetUserInfoLocked", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

    DeleteAll(){
      this.$refs.MsgBoxRef2.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          (this.selected.length) + "개 항목]"
      );
//
    },

    onMsgBoxFuncAll(result){
      if (result === true) {        
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          zUserId: [],
        };

        for (let i in this.selected) {
          posData.zUserId.push(this.selected[i].cUserId);            
        }

        console.log(posData);
        axios
          .post(BasicInfo.UIL_API + "DelUserSelected", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd === "00") {              
              for (let i in this.selected) {
                for (let j in this.Records) {
                  if (this.Records[j].cUserId == this.selected[i].cUserId) {
                    this.Records.splice(j, 1); 
                  }                  
                }
              }
              this.selected = [];
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },

    uploadExcel(){
      this.$refs.UploadExcelRef.Show();
    },

    onReadFile(data){
      this.FileRecords = [];
      if (data.length == 0) {
        return
      }

      for (let i = 0; i < data.length; i++) {
        var a = {
          cUserId : String(data[i].사용자ID),
          cPwd : "",
          cUserNm : String(data[i].이름),
          cCorpId : String(data[i].회사코드),
          cCorpNm : data[i].회사명,
          cPartNm : data[i].부서,
          cPartLev : data[i].직책,
          cIsEmployed : data[i].재직여부,
          cEmail : data[i].이메일,
          cTel : data[i].연락처,
          cBirth : data[i].생년월일,
          cLev : data[i].구분,          
          cLocked : "N",
          cUse : "N",
        }

        if (data[i].잠금 == "Y"){
          a.cLocked = "Y";
        }
        if (data[i].사용 == "Y"){
          a.cUse = "Y";
        }

        a.cPwd = SHA256(a.cUserId + "1Qwer1234!" + "@" + a.cUserId);

       
        this.FileRecords.push(a);
      }      

      this.bReadFile = true;
    }, 

    uploadExcelFile(){      
      var posData = {
        ZData : this.FileRecords,        
      }
      
      this.loading2 = true;

      
      axios
        .post(BasicInfo.UIL_API + "UploadExcelUserInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
            this.bReadFile = false;
            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading2 = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading2 = false;          
        });
    },  

    GetMapItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item); 
      this.$refs.GetSelMapRef.show(this.editedItem.zMap); 
    },

    onGetMap(zSelect, cMapNms, cMapCds) {
      this.editedItem.cMapCds = cMapCds;
      this.editedItem.cMapNms = cMapNms;
    
      var posData = {        
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        cUserId : this.editedItem.cUserId, 
        cMapCds : this.editedItem.cMapCds,
        cMapNms : this.editedItem.cMapNms,
      };      
      console.log(zSelect);
      axios
        .post(BasicInfo.UIL_API + "SetUserMap", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd === "00") {          
            Object.assign(this.Records[this.editedIndex], this.editedItem);
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    SelectUserAll(){
//
      this.$refs.GetSelMapRef2.show([]); 
    },

    onGetMap2(zSelect, cMapNms, cMapCds) {
      
      var posData = {        
        cUpdateUserId: this.$store.state.UserInfo.UserId,
        zUserId : [], 
        cMapCds : cMapCds,
        cMapNms : cMapNms,
      };      

      for (let i in this.selected) {
        posData.zUserId.push(this.selected[i].cUserId);            
      }

      console.log(zSelect);
      axios
        .post(BasicInfo.UIL_API + "SetUserMapALL", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd === "00") {          
            for (let i in this.selected) {
              for (let j in this.Records) {
                if (this.Records[j].cUserId == this.selected[i].cUserId) {
                  // console.log(this.Records[j].cUserId, cMapCds, cMapNms);
                  this.Records[j].cMapCds = cMapCds; 
                  this.Records[j].cMapNms = cMapNms; 
                }                  
              }
            }
            this.selected = [];            
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    GetSKMRMember() {
      var posData = {}
      
      this.loading3 = true;
      axios
        .post(BasicInfo.UIL_API + "GetSKMRMember", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "불러오기를 성공하였습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }          
          this.loading3 = false;            
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading3 = false;           
        });
    },
  }
};
</script>


